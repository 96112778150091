import React, { ChangeEvent, SyntheticEvent, MouseEvent, useEffect, useState } from 'react';
import { TextareaAutosize } from 'extended-oxygen-elements';
import { FormContainer, FormItem, Paragraph, TextArea } from 'elmo-elements';
import './styles.scss';

type Props = {
  value: null | string;
  setNotesValue: (name: string, value: string) => void;
  tabIndex?: number;
};

export const NotesFieldExpandable = ({ value, setNotesValue, tabIndex = -1 }: Props) => {

  const maxTextLength = 500;
  const diff = !value ? maxTextLength : (maxTextLength - value.length);
  const [remainingNumber, setRemainingNumber] = useState(diff);
  const [placeholder, setPlaceholder] = useState(true);

  const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { currentTarget } = e;
    setNotesValue('notes', currentTarget.value.substring(0, maxTextLength));
  };

  const setTextLength = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    const diff = maxTextLength - value.length;
    setRemainingNumber(diff);
    if ( value.length >= maxTextLength ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setRemainingNumber(!value ? maxTextLength : (maxTextLength - value.length));
  });

  const setFocus = () => {
    setTimeout(() => {
      const notesField = document.querySelector('.roster-notes-field');
      if ( notesField ) {
        (notesField as HTMLTextAreaElement).focus();
      }
    }, 400);
  };

  const openField = (e: MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    setPlaceholder(false);
    setFocus();
  };

  const validateField = (e: any) => {
    const { currentTarget } = e;
    setNotesValue('notes', currentTarget.value.trim().substring(0, maxTextLength));
    setPlaceholder(true);
  };

  const showPlaceholder = () => {
    return (
      <FormItem label={'Notes'}>
        <div onClick={openField} className={'notes-placeholder'} tabIndex={tabIndex} onFocus={openField}>
          <Paragraph isTruncate={true} size={'sm'} color={'gray'}>
            {!value ? 'Click to add notes ...' : value}
          </Paragraph>
        </div>
      </FormItem>
    );
  };

  const showNotesField = () => {
    return (
      <FormItem label={`Notes (${remainingNumber})`}>
        <TextArea
          component={TextareaAutosize}
          name={'notes'}
          className={'roster-notes-field'}
          onChange={onChangeValue}
          onKeyUp={setTextLength}
          value={!value ? '' : value}
          onBlur={validateField}
          tabIndex={tabIndex}
        />
      </FormItem>
    );
  };

  return (
    <FormContainer maxColumns={1}>
      {
        placeholder
          ? showPlaceholder()
          : showNotesField()
      }
    </FormContainer>
  );
};
