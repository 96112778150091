import { get } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import { ApiClientResponse, ServerResponse } from 'type';
import HTTPClient from 'lib/HTTPClient';
import AuthService from 'lib/AuthService';
import responseTransformers, {
  defaultResponseTransformer,
} from './responseTransformers';
import makeRequestWaiter from './makeRequestWaiter';

const baseURL = process.env.REACT_APP_API_URL as string;

const ApiClient = new HTTPClient({
  baseURL,
  withCredentials: false,
  paramsSerializer: (params: any) => qs.stringify(params, { skipNulls: true }),

  async onTransformResponse({
    data: response,
  }: AxiosResponse<ServerResponse<any>>): Promise<ApiClientResponse<any>> {
    const { status_code } = response;
    const handler = responseTransformers[status_code];
    if (handler) {
      const transformedResponse = await handler(response);
      return transformedResponse || defaultResponseTransformer(response);
    }

    return defaultResponseTransformer(response);
  },
  onBeforeRequest() {
    return {
      headers: AuthService.getAuthHeaders(),
    };
  },
  onCatchNetworkError(networkError) {
    const status = getErrorStatus(networkError);

    if (status === 401) {
      return handleUnauthorizedError.call(this, networkError);
    }
  },
});

function getErrorStatus(networkError: AxiosError): number {
  return get(networkError, 'response.status', -1);
}

const refreshApiClient = new HTTPClient({
  baseURL,
  withCredentials: true,
});

const refreshToken = makeRequestWaiter(() =>
  refreshApiClient.get('/api/bravo/auth/refresh_token')
);

async function handleUnauthorizedError(
  this: HTTPClient,
  originalError: AxiosError
) {
  try {
    await refreshToken();
  } catch (refreshTokenError) {}

  return this.request({
    ...originalError.config,
    headers: AuthService.getAuthHeaders(),
  })
    .then(this.config.onTransformResponse)
    .catch(onUnauthorizedResponse);
}

export async function onUnauthorizedResponse() {
  AuthService.clearAccountId();
  window.location.href = process.env.REACT_APP_BREATHE_HR_URL as string;
  return new Promise((resolve) => setTimeout(resolve, 10000)); // locking app until the page is reloaded
}

export default ApiClient;
