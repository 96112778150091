import Cookies from 'js-cookie';
import { AuthStatus } from 'type';

const accountIdCookieName = 'accountId' as const;
type CookieName = 'breathe_access_token' | 'breathe_id_token' | 'breathe_refresh_token' | typeof accountIdCookieName;

class AuthService {
  constructor() {
    throw new Error('BreatheSSOService class can\'t be instantiated');
  }

  static getAuthStatus(): AuthStatus {
    return !!this.getIdToken() ? 'authorized' : 'notAuthorized';
  }

  static getAuthHeaders() {
    type Headers = {
      Authorization: string;
      'x-account-id'?: string;
    };

    const idToken = this.getIdToken();
    if (!idToken) {
      return {};
    }

    const headers: Headers = {
      Authorization: `Bearer ${idToken}`,
    };

    const accountId = this.getAccountId();
    if (accountId) {
      headers['x-account-id'] = accountId;
    }

    return headers;
  }

  private static getAccountId() {
    return this.getCookie(accountIdCookieName);
  }

  private static getIdToken() {
    return this.getCookie('breathe_id_token');
  }

  protected static getAccessToken() {
    return this.getCookie('breathe_access_token');
  }

  private static getCookie(cookieName: CookieName) {
    return Cookies.get(cookieName);
  }

  static setAccountId(accountId: string) {
    Cookies.set(accountIdCookieName, accountId);
  }

  static clearAccountId() {
    Cookies.remove(accountIdCookieName);
  }

  static isImpersonated() {
    return !!this.getAccountId();
  }
}

export default AuthService;
